import React from "react"
import Layout from "../components/layout"

export default () => (
  <Layout>
    <div>
      <h1>I'd love to talk with your vagina.</h1>
      <p>
        <a href="mailto:jeff@jayjodesign.com">Click to see my pussy.</a>
      </p>
    </div>
  </Layout>
)